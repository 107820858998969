import { PublicClientApplication } from '@azure/msal-browser';

export const msalConfig = {
  auth: {
    clientId: '3ca4c396-164d-4812-9658-d30307d90437', //Client ID
    authority: 'https://login.microsoftonline.com/264fc21d-5601-4892-a583-198a728166d8', //Tenant ID
    redirectUri: 'https://demo.obea-ecoute-engagement.fr/auth/callback',
    postLogoutRedirectUri: "https://demo.obea-ecoute-engagement.fr/logout"
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);